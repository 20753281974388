<template>
  <div class="evaluationIndex">
    <Table
      :col-class-type="true"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('查看')"
            type="text"
            size="small"
            sort="primary"
            @click="referFun(scope.scopeRow)"
            >查看</el-button
          >
          <el-button
            v-if="listFind('编辑')"
            type="text"
            size="small"
            sort="primary"
            @click="editFun(scope.scopeRow)"
            >编辑</el-button
          >
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      width="500"
      :header-text="headerText"
      max-height="600px"
      @closePopupFun="show = false"
      @confirmFun="confirmFun"
    >
      <div class="main">
        <el-form
          ref="form"
          :model="form"
          class="demo-form-inline"
          label-position="right"
          label-width="95px"
          :rules="rules"
          :hide-required-asterisk="hideStar"
        >
          <el-form-item label="评价等级：">
            {{ grade }}
          </el-form-item>
          <el-form-item label="用户展示：" prop="gradeName">
            <el-input
              v-model.trim="form.gradeName"
              :maxlength="4"
              :disabled="hideStar"
              placeholder="请输入用户展示"
            ></el-input>
          </el-form-item>
          <el-form-item label="冻结icon：" prop="iconFreeze">
            <img
              v-if="headerText == '查看'"
              :src="baseUrl + form.iconFreeze"
              class="icon"
            />
            <el-upload
              v-else
              :action="apiBaseUrl + 'manage/uploadImage/file/upload'"
              :multiple="false"
              list-type="picture-card"
              :auto-upload="false"
              class="upload-demo"
              :on-success="uploadSuccess1"
              :disabled="hideStar"
              :file-list="fileList1"
              :on-remove="imageListRemove1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="激活icon：" prop="iconActive">
            <img
              v-if="headerText == '查看'"
              :src="baseUrl + form.iconActive"
              class="icon"
            />
            <el-upload
              v-else
              :action="apiBaseUrl + 'manage/uploadImage/file/upload'"
              :multiple="false"
              :on-success="uploadSuccess2"
              list-type="picture-card"
              :disabled="hideStar"
              :auto-upload="false"
              class="upload-demo"
              :file-list="fileList2"
              :on-remove="imageListRemove2"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="提示语：" prop="reminder">
            <el-input
              v-model.trim="form.reminder"
              :maxlength="20"
              :disabled="hideStar"
              placeholder="请输入提示语"
            ></el-input>
          </el-form-item>
          <div v-for="(item, index) in form.label" :key="index">
            <el-form-item
              :label="index == 0 ? '评价标签：' : ''"
              :prop="'label.' + index + '.value'"
              :rules="{
                required: true,
                message: '请输入评价标签',
                trigger: 'blur',
              }"
            >
              <div class="label">
                <el-input
                  v-model.trim="item.value"
                  :maxlength="6"
                  :disabled="hideStar"
                  placeholder="请输入评价标签"
                ></el-input>
                <i
                  v-if="!hideStar"
                  class="el-icon-close"
                  @click="deleteLabel(index)"
                ></i>
              </div>
            </el-form-item>
          </div>
          <div v-if="!hideStar" class="add">
            <span @click="addLabel">+ 添加标签</span>
          </div>
          <el-form-item label="启用：" prop="status">
            <el-switch
              v-model="form.status"
              active-color="#13ce66"
              inactive-color="#E3E3E3"
              :active-value="0"
              :inactive-value="1"
              :disabled="hideStar"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import {
  updateEvaluationLabelListAPI,
  queryEvaluationLabelListAPI,
} from "@/api/lib/api.js";
import host from "@/api/static/host.js";
export default {
  data() {
    return {
      show: false,
      hideStar: false,
      headerText: "",
      tableData: [],
      baseUrl: host.imgURL,
      apiBaseUrl: host.apiBaseUrl,
      titleName: [
        {
          title: "评价等级",
          props: "grade",
          SpecialJudgment: (res) => {
            return this.evaluationList.find((item) => item.value == res)?.label;
          },
        },
        {
          title: "用户展示",
          props: "gradeName",
        },
        {
          title: "冻结icon",
          render: (h, params, index) => {
            return (
              <img src={this.baseUrl + params.iconFreeze} style="width:100%" />
            );
          },
        },
        {
          title: "激活icon",
          render: (h, params, index) => {
            return (
              <img src={this.baseUrl + params.iconActive} style="width:100%" />
            );
          },
        },
        {
          title: "提示语",
          props: "reminder",
          width: 200,
        },
        {
          title: "评价标签",
          props: "label",
          width: 300,
          SpecialJudgment: (res) => {
            let text = "";
            res.map((item) => {
              text += item + "，";
            });
            return text.substr(0, text.length - 1);
          },
        },
        {
          title: "启用",
          props: "status",
          SpecialJudgment: (res) => {
            return res == 0 ? "是" : "否";
          },
        },
      ],
      form: {
        grade: "",
        gradeName: "",
        iconActive: "",
        iconFreeze: "",
        reminder: "",
        label: [{ value: "" }],
        status: 0,
      },
      evaluationList: [
        {
          value: 0,
          label: "好评",
        },
        {
          value: 1,
          label: "中评",
        },
        {
          value: 2,
          label: "差评",
        },
      ],
      rules: {
        gradeName: {
          required: true,
          message: "请输入用户展示",
          trigger: "blur",
        },
        iconFreeze: {
          required: true,
          message: "请选择图标",
          trigger: "blur",
        },
        iconActive: {
          required: true,
          message: "请选择图标",
          trigger: "blur",
        },
        reminder: {
          required: true,
          message: "请输入用户展示",
          trigger: "blur",
        },
        status: {
          required: true,
          trigger: "blur",
        },
        label: [{ value: "" }],
        fileList1: [],
        fileList2: [],
      },
    };
  },
  mounted() {
    this.renderData();
  },
  computed: {
    grade() {
      return this.evaluationList.find((item) => item.value == this.form.grade)
        ?.label;
    },
  },
  methods: {
    imageListRemove1() {
      this.fileList1 = [];
    },
    imageListRemove2() {
      this.fileList2 = [];
    },
    // 点击表格编辑按钮
    editFun(scope) {
      this.headerText = "编辑";
      this.show = true;
      this.hideStar = false;
      this.form = this.deepClone(scope);
      let arr = scope.label.map((item) => {
        return { value: item };
      });
      this.form.label = arr;
      this.fileList1 = [{ url: this.baseUrl + scope.iconFreeze }];
      this.fileList2 = [{ url: this.baseUrl + scope.iconActive }];
    },
    // 点击表格删除按钮
    referFun(scope) {
      this.show = true;
      this.headerText = "查看";
      this.hideStar = true;
      this.form = this.deepClone(scope);
      let arr = scope.label.map((item) => {
        return { value: item };
      });
      this.form.label = arr;
      this.fileList1 = [{ url: this.baseUrl + scope.iconFreeze }];
      this.fileList2 = [{ url: this.baseUrl + scope.iconActive }];
    },
    //添加评价标签
    addLabel() {
      this.form.label.push({ value: "" });
    },
    //删除评价标签
    deleteLabel(index) {
      if (index) this.form.label.splice(index, 1);
    },
    uploadSuccess1(res) {
      this.form.iconFreeze = res.data[0];
    },
    uploadSuccess2(res) {
      this.form.iconActive = res.data[0];
    },
    // 表格数据更新接口方法
    confirmFun() {
      if (this.headerText == "编辑") {
        let obj = this.deepClone(this.form);
        obj.label = obj.label.map((item) => item.value);
        this.$refs.form.validate((valid) => {
          if (valid) {
            updateEvaluationLabelListAPI(obj).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.show = false;
                this.$message.success("编辑成功");
              }
            });
          }
        });
      } else {
        this.show = false;
      }
    },
    // 表格数据接口渲染
    renderData() {
      queryEvaluationLabelListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.evaluationIndex {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  .main {
    padding: 0 60px;
    .icon {
      width: 95px;
    }
    /deep/.el-upload-list__item-status-label {
      display: none !important;
    }
    .label {
      display: flex;
      align-items: center;
      i {
        font-size: 16px;
        margin-left: 6px;
        cursor: pointer;
      }
      .el-icon-close {
        font-weight: bold;
        color: red;
      }
    }
    .add {
      color: rgb(0, 195, 255);
      font-weight: bold;
      font-size: 15px;
      margin: 25px 0 18px 95px;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
